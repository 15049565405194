@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100vh !important;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.content {
  height: 100vh !important;
  width: calc(100% - 270px);
  font-family: "Source Sans Pro", sans-serif;
}

.content-body{
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}
p {
  font-size: 1rem !important;
}
table {
  font-size:  1rem !important;
}
:root {
  --font-size: 1rem !important;
}
button {
  font-size: 0.9rem !important;
}
/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::MuiGrid-item {
  margin: 0;
  padding: 0;
  width: 100%;
}

.box {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  font-size: larger;
  align-items: center;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mt-6 {
  margin-top: 6rem;
}
.mt-7 {
  margin-top: 7rem;
}
.mt-8 {
  margin-top: 8rem;
}
.mt-9 {
  margin-top: 9rem;
}
.mt-10 {
  margin-top: 10rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-4 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}
.ml-6 {
  margin-left: 6rem;
}
.ml-7 {
  margin-left: 7rem;
}
.ml-8 {
  margin-left: 8rem;
}
.ml-9 {
  margin-left: 9rem;
}
.ml-10 {
  margin-left: 10rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}
.mr-4 {
  margin-right: 4rem;
}
.mr-5 {
  margin-right: 5rem;
}
.mr-6 {
  margin-right: 6rem;
}
.mr-7 {
  margin-right: 7rem;
}
.mr-8 {
  margin-right: 8rem;
}
.mr-9 {
  margin-right: 9rem;
}
.mr-10 {
  margin-right: 10rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mb-6 {
  margin-bottom: 6rem;
}
.mb-7 {
  margin-bottom: 7rem;
}
.mb-8 {
  margin-bottom: 8rem;
}
.mb-9 {
  margin-bottom: 9rem;
}
.mb-10 {
  margin-bottom: 10rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}
.m-5 {
  margin: 5rem;
}
.m-6 {
  margin: 6rem;
}
.m-7 {
  margin: 7rem;
}
.m-8 {
  margin: 8rem;
}
.m-9 {
  margin: 9rem;
}
.m-10 {
  margin: 10rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pt-6 {
  padding-top: 6rem;
}
.pt-7 {
  padding-top: 7rem;
}
.pt-8 {
  padding-top: 8rem;
}
.pt-9 {
  padding-top: 9rem;
}
.pt-10 {
  padding-top: 10rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-3 {
  padding-left: 3rem;
}
.pl-4 {
  padding-left: 4rem;
}
.pl-5 {
  padding-left: 5rem;
}
.pl-6 {
  padding-left: 6rem;
}
.pl-7 {
  padding-left: 7rem;
}
.pl-8 {
  padding-left: 8rem;
}
.pl-9 {
  padding-left: 9rem;
}
.pl-10 {
  padding-left: 10rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pr-3 {
  padding-right: 3rem;
}
.pr-4 {
  padding-right: 4rem;
}
.pr-5 {
  padding-right: 5rem;
}
.pr-6 {
  padding-right: 6rem;
}
.pr-7 {
  padding-right: 7rem;
}
.pr-8 {
  padding-right: 8rem;
}
.pr-9 {
  padding-right: 9rem;
}
.pr-10 {
  padding-right: 10rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.pb-5 {
  padding-bottom: 5rem;
}
.pb-6 {
  padding-bottom: 6rem;
}
.pb-7 {
  padding-bottom: 7rem;
}
.pb-8 {
  padding-bottom: 8rem;
}
.pb-9 {
  padding-bottom: 9rem;
}
.pb-10 {
  padding-bottom: 10rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}
.p-5 {
  padding: 5rem;
}
.p-6 {
  padding: 6rem;
}
.p-7 {
  padding: 7rem;
}
.p-8 {
  padding: 8rem;
}
.p-9 {
  padding: 9rem;
}
.p-10 {
  padding: 10rem;
}
